import React from 'react';
import "./commonSelect.scss";

function CommonSelect({ data, first = "", onChange, flag, selected, referance, name = "", text = "Client List", type = "" ,weekNum="",getMonthName}) {

  let options = null;

  switch (type) {
    case "date":
      // If type is "date", you can handle the rendering logic here
      options = (
        data.map((date)=>{
            // console.log(date);
            return (
              <>
              { (weekNum-1)*7 < date[0] && date[0] <=weekNum*7 && <option key={date[0]} value={date[0]}>
                {date[0]}
              </option>}
              </>
            );
          })
      );
      break;
      case "month":
        options=(
            data.map((month) => (
                <option key={month[0]} value={month[0]}>
                  {getMonthName(month[0])}
                </option>))
        )
        break;
    default:
      // For other types or if type is not provided, render the default options
      options = (
        <>
          {flag ? (
            data.map((client, idx) => {
             
              return (
              <option
                key={`${client.clientid}${idx}`}
                value={`${client.clientid}-${client.clientname.trim()}`}
                selected={client.clientid === Number(selected)}
              >
                {client.clientname.trim()}
              </option>
            )})
          ) : (
            data?.map((item, idx) => (
              <option key={`${item?.id}${idx}`||item} value={item?.id || item}>
                {item?.name || item}
              </option>
            ))
          )}
        </>
      );
  }

  return (
    <div className="select-wrapper">
      {flag? <select onChange={onChange} name={name} ref={referance}>
        {first.length ? <option value={first} defaultChecked>{first}</option> : <option value="" disabled selected>Select below</option>}
        {options}
      </select>:
      <select value={selected} onChange={onChange} name={name} ref={referance}>
      {first.length ? <option value={first} defaultChecked>{first}</option> : <option value="" disabled selected>Select below</option>}
      {options}
    </select>
      }
      {text && <div className="client-text">{text}</div>}
    </div>
  );
}

export default CommonSelect;
