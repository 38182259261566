import * as types from "../reducers/types";
import APIConstant from "../../utility/APIConstant";

const initialState = {
  acl: {},
  isLoggedIn: false,
  createdUser: {},
  loggedInUser: {},
  allCourses: [],
  allUsers: [],
  assignedCourses: [],
  userCache:[],
  activeUserCache:[],
  scormList: [],
  clients: [],
  authInfo: null,
  supplementaries: {},
  flathistory: {},
  allCourseStructure: [],
  adminTags: { data: {}, tags: [], externalIdx: -1 },
  statsData: {
    filteredData: {},
    maxVisual: null,
  },
  courseCategory:[],
  companySeat: {},
  companyData: [],
  companyStats: {},
  clientData: {
    data:[],
    clPage:1,
    clients:{
    }
  },
  clientCoursesData: {},
  userCoursesData:{},
  statisticTab:{
    dateFrom:"",
    dateTo:"",
    clientId:0,
    clientName:"",
    select:{
      yearSel: "",
      monthSel: "",
      weekSel: "",
      dateSel: "",
    },
    data:{},
    show:false,
    textData:[],
  },
  companyDataLoader: false,
  clientUserDataLoader: false,
  isconfigured:false,
  scromPackages:{},
  isLoading:false,
  languagesList:{},
  languageConfig:"",
  multilingualConfig:[],
  courseApproval:[],
  textDownload:true,
  quizDownload:true,
  directLogin:false,
  directLoginFail:false,
  s3Config:{},
  decryptPass:"",
  courseQuizData:{}
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_RLMUSER_RES:
      let addedUser = {};
      if (action?.payload?.status === "success") {
        addedUser = action?.payload?.data;
        let obj = { user: action?.payload?.data, courses: [] };
        state.allUsers.push(obj);
      }
      return {
        ...state,
        createdUser: addedUser,
      };
    case types.LOGGEDOUT_USER_REQ:
      return {
        ...state,
        acl: {},
        isLoggedIn: false,
        createdUser: {},
        loggedInUser: {},
        allCourses: [],
        allUsers: [],
        assignedCourses: [],
        scormList: [],
        clients: [],
        authInfo: null,
        supplementaries: {},
        flathistory: {},
        allCourseStructure: [],
        adminTags: { data: {}, tags: [], externalIdx: -1 },
        statsData: {
          filteredData: {},
          maxVisual: 0,
        },
        statisticTab:{
          dateFrom:"",
          dateTo:"",
          clientId:0,
          clientName:"",
          select:{
            yearSel: "",
            monthSel: "",
            weekSel: "",
            dateSel: "",
          },
          data:{},
          show:false,
          textData:[],
        },
        companyData: [],
        companySeat: {},
        companyStats: {},
        languagesList:{},
        multilingualConfig:[],
        directLogin:true,
        s3Config:{}
      };
    case types.RLMUSER_LOGIN_RES:
      let loggedInUser = {};
      let isLoggedIn = false;
      if (action?.payload?.status === "success") {
        let user = {
          email: action?.payload?.data[0].email,
          role: action?.payload?.data[0].role,
        };
        window.sessionStorage.setItem("user", JSON.stringify(user));
        loggedInUser = action?.payload?.data[0];
        isLoggedIn = true;
      } else {
        isLoggedIn = false;
      }
      return {
        ...state,
        loggedInUser: loggedInUser,
        isLoggedIn: isLoggedIn,
      };
    case types.USER_ASSIGNED_COURSES_RES:
      let courses = [];
      if (action?.payload?.status === "success") {
        courses = action?.payload?.data?.assignedcourses;
      }
      return {
        ...state,
        assignedCourses: courses,
      };
    case types.USER_ASSIGNED_COURSES_RES:
      let assigned = [];
      if (action?.payload?.status === "success") {
        assigned = action?.payload?.data?.assignedcourses;
      }
      return {
        ...state,
        assignedCourses: courses,
      };
    case types.ALL_COURSES_RES:
      let allCourses = [];
      if (action?.payload?.status === "success") {
        allCourses = action?.payload?.data;
      }
      return {
        ...state,
        allCourses: allCourses,
      };
    case types.ALL_USERS_RES:
      let users = [];
      if (action?.payload?.status === "success") {
        users = action?.payload?.data;
      }
      return {
        ...state,
        allUsers: users,
      };
    case types.ADMIN_ACL_RES:
      let acl = [];
      if (action?.payload?.status === "success") {
        let response = action?.payload?.data;
        acl = response.find((item) => {
          return item._id === "6270adcbca076d36eee9e9e2";
        });
        console.log("ACL");
        console.log(acl);
      }
      return {
        ...state,
        acl: acl,
      };
    case types.COURSE_ASSIGNMENT_RES:
      console.debug(action?.payload?.res?.status);
      if (action?.payload?.res?.status === "success") {
        console.debug("action?.payload?.res?", action?.payload?.res);
      }
      return {
        ...state,
      };
    case types.USER_COURSE_ASSIGNMENT_RES:
      console.debug(action?.payload?.res?.status);
      if (action?.payload?.res?.status === "success") {
        console.debug("action?.payload?.res?", action?.payload?.res);
      }
      return {
        ...state,
      };
    case types.COURSE_ASSIGN_RES:
      console.debug(action?.payload?.res?.status);
      if (action?.payload?.res?.status === "success") {
        console.debug("action?.payload?.res?", action?.payload?.res);
      }
      return {
        ...state,
      };
    case types.CREATE_SCORM_PACK_SUCCESS:
      let list = state.scormList;
      list.push(action?.payload);
      return {
        ...state,
        scormList: list,
      };

    case types.ASSOCIATE_CLIENTS:
      let clientlist = [];
      if (action?.payload.data && state.loggedInUser.clients.length > 0) {
        const clients = action?.payload.data;

        clientlist = state.loggedInUser.clients.map((item) => {
          const match = clients.find((cl) => {
            return cl._id === item.id;
          });
          item = match;
          return match;
        });
        state.loggedInUser.clients = clientlist;
      }
      return { ...state, clients: clientlist };

    case types.LMS_AUTH_RESPONSE:
      console.log();
      let authtoken = {};
      if (action.payload.result === 1 && action.payload.status === "success") {
        authtoken = action.payload?.data;
      }
      return { ...state, authInfo: authtoken };

    case types.SELECTED_SUPPLIMENTARY_RES:
      let allSupplementaries = action?.payload.data;
      let updates = [],
        vignettes = [],
        notes = [];
      if (allSupplementaries?.length > 0) {
        allSupplementaries.forEach((el) => {
          if (el.stype === 1) updates.push(el);
          if (el.stype === 2) notes.push(el);
          if (el.stype === 3) vignettes.push(el);
        });
      }
      return {
        ...state,
        supplementaries: {
          updates: updates,
          vignettes: vignettes,
          notes: notes,
        },
      };
    case types.ADD_SUPPLIMENTARY_RES:
      let newSupplementaries = action?.payload?.data;
      let newUpdate = state?.supplementaries?.updates;
      let newVignette = state?.supplementaries?.vignettes;
      let newNote = state?.supplementaries?.notes;
      if (newSupplementaries?.status === 1) {
        if (newSupplementaries.stype === 1) {
          newUpdate?.push({ ...newSupplementaries });
        }
        if (newSupplementaries?.stype === 2) {
          newNote?.push({ ...newSupplementaries });
        }
        if (newSupplementaries?.stype === 3) {
          newVignette?.push({ ...newSupplementaries });
        }
        return {
          ...state,
          supplementaries: {
            updates: newUpdate,
            vignettes: newVignette,
            notes: newNote,
          },
        };
      }
    case types.UPDATE_SUPPLIMENTARY_RES:
      let updateSupplementaries = action?.payload.data;
      let update_Update = state?.supplementaries?.updates;
      let updateVignette = state?.supplementaries?.vignettes;
      let updateNote = state?.supplementaries?.notes;
      if (action?.payload.result.result === 1) {
        if (updateSupplementaries.stype === 1) {
          update_Update = update_Update.map((e) => {
            if (e._id === updateSupplementaries._id) {
              return { ...updateSupplementaries };
            }
            return e;
          });
        }
        if (updateSupplementaries.stype === 2) {
          updateNote = updateNote.map((e) => {
            if (e._id === updateSupplementaries._id) {
              return { ...updateSupplementaries };
            }
            return e;
          });
        }
        if (updateSupplementaries.stype === 3) {
          updateVignette = updateVignette.map((e) => {
            if (e._id === updateSupplementaries._id) {
              return { ...updateSupplementaries };
            }
            return e;
          });
        }
        return {
          ...state,
          supplementaries: {
            updates: update_Update,
            vignettes: updateVignette,
            notes: updateNote,
          },
        };
      }
    case types.SELECTED_HISTORY_RES:
      let allcourse = state?.allCourseStructure;
      iteratelist(
        action?.payload?.data?.coursedata,
        0,
        "",
        action.payload?.topicdata,
        state?.flathistory
      );
      if (action?.payload?.data?.coursedata) {
        allcourse[action?.payload?._id] = state?.flathistory;
      }
      return { ...state, allCourseStructure: allcourse };
    case types.SELECTED_HISTORY_IN_LIST:
      return { ...state, flathistory: action?.payload };
    case types.SELECTED_MENU:
      let history = action?.payload === 5 ? state?.flathistory : {};
      return { ...state, flathistory: history };
    case types.ADMIN_TAGS_RES:
      let response = action?.payload;
      return {
        ...state,
        adminTags: {
          data: response?.data,
          tags: response?.adminTags,
          externalIdx: response?.externalIdx,
        },
      };
    case types.STATS_DATA_RES:
      return {
        ...state,
        statsData: { ...action?.payload },
      };
    case types.COMPANY_DATA_RES:
      return {
        ...state,
        companyData: [...action?.payload],
      };
    case types.SELECTED_COMPANY_DATA_RES:
      return {
        ...state,
        companyStats: { ...action?.payload },
      };
    case types.SELECTED_COMPANY_DATA_SEAT_RES:
      return {
        ...state,
        companySeat: { ...action?.payload },
      };
    case types.SET_COMPANY_LOADER_DATA:
      return {
        ...state,
        companyDataLoader: action?.payload,
      };
    case types.SET_CLIENTUSER_LOADER_DATA:
      return {
        ...state,
        clientUserDataLoader: action?.payload,
      };
      case types.SELECTED_CLIENT_DATA_RES:
        
          return {
            ...state,
            clientData: { ...action?.payload },
          }
    case types.SELECTED_CLIENT_COURSES_DATA_RES:
      return {
        ...state,
        clientCoursesData: { ...state.clientCoursesData, ...action?.payload },
      }
    case types.COURSE_CATEGORY_RES:
      return {
        ...state,
        courseCategory: [
          ...action?.payload.sort((a, b) => a.order - b.order)
      ],
      }
      case types.USER_CACHE_RES:
      return {
        ...state,
        userCache:  [ ...action?.payload ],
      }
      case types.ACTIVE_USER_CACHE_RES:
      return {
        ...state,
        activeUserCache:  [ ...action?.payload ],
      }
    case types.COURSE_CONFIG_RES:
      return {
        ...state,
       isconfigured:action?.payload,
      }
    case types.SCROM_PACKAGE_RES:
      return {
        ...state,
       scromPackages:action?.payload,
      }
    case types.LANGUAGE_PACKAGE_RES:
      return {
        ...state,
       languagesList:[...action?.payload],
      }
    case types.LANGUAGE_CONFIG_RES:
      return {
        ...state,
       languageConfig:action?.payload
      }
      case types.SET_SCROM_LOADER:
        return {
          ...state,
          isLoading: action?.payload,
        };
      case types.SET_STATISTIC_TAB_DATA:
        return {
          ...state,
          statisticTab: action?.payload,
        };
      case types.SET_MULTILINGUAL_CONFIG:
        return {
          ...state,
          multilingualConfig: action?.payload,
        };
      case types.SET_SELECTED_CLIENT_DATA_RES:
        return{
          ...state,
          multilingualConfig:action?.payload
        }
      case types.SET_COURSE_APPROVAL_RES:
        return{
          ...state,
          courseApproval:[...state.courseApproval,...action?.payload]
        }
      case types.TEXT_DOWNLOAD_RES:
        return{
          ...state,
          textDownload:action?.payload
        }
      case types.QUIZ_DOWNLOAD_RES:
        return{
          ...state,
          quizDownload:action?.payload
        }
      case types.DIRECT_LOGIN_REQ:
        return{
          ...state,
          directLogin:action?.payload
        }
      case types.DIRECT_LOGIN_FAILURE:
        return{
          ...state,
          directLoginFail:action?.payload
        }
      case types.S3_CONFIG_RES:
        return{
          ...state,
          s3Config:action?.payload
        }
      case types.PASSWORD_ENCRYPT_RES:
        return{
          ...state,
          decryptPass:action?.payload
        }
      case types.COURSE_QUIZ_DATA_RES:
        return{
          ...state,
          courseQuizData: {...state.courseQuizData, ...action?.payload}
        }

    default:
      return state;
  }
};

const iteratelist = (list, cnt, parent, topics, flat) => {
  APIConstant.listIterator(list, cnt, parent, topics, flat);
};

export default userReducer;
